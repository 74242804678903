import './linktracker/linktracker';
import {parseUrl} from 'dom-utils';

let trackingreturn = false;
let scriptmode;
let clientId;

let baseUrl = 'https://meerclick.vergelijkgroep.nl/';


if (typeof window._paq === 'undefined') {
    window._paq = [];
}

function parseUri (str) {
    var	o   = parseUri.options,
        m   = o.parser[o.strictMode ? "strict" : "loose"].exec(str),
        uri = {},
        i   = 14;

    while (i--) uri[o.key[i]] = m[i] || "";

    uri[o.q.name] = {};
    uri[o.key[12]].replace(o.q.parser, function ($0, $1, $2) {
        if ($1) uri[o.q.name][$1] = $2;
    });

    return uri;
}

parseUri.options = {
    strictMode: false,
    key: ["source","protocol","authority","userInfo","user","password","host","port","relative","path","directory","file","query","anchor"],
    q:   {
        name:   "queryKey",
        parser: /(?:^|&)([^&=]*)=?([^&]*)/g
    },
    parser: {
        strict: /^(?:([^:\/?#]+):)?(?:\/\/((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?))?((((?:[^?#\/]*\/)*)([^?#]*))(?:\?([^#]*))?(?:#(.*))?)/,
        loose:  /^(?:(?![^:@]+:[^:@\/]*@)([^:\/?#.]+):)?(?:\/\/)?((?:(([^:@]*)(?::([^:@]*))?)?@)?([^:\/?#]*)(?::(\d*))?)(((\/(?:[^?#](?![^?#\/]*\.[^?#\/.]+(?:[?#]|$)))*\/?)?([^?#\/]*))(?:\?([^#]*))?(?:#(.*))?)/
    }
};

function j_meer_loadScript(url, callback) {
    var script = document.createElement("script")
    script.type = "text/javascript";

    if (script.readyState) { //IE
        script.onreadystatechange = function () {
            if (script.readyState == "loaded" || script.readyState == "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else { //Others
        script.onload = function () {
            callback();
        };
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
}

// Google Analytics 4 (GA4):
if (typeof window.ga4_stream !== 'undefined') {
    // Load Analytics:
    let ga4ScriptUrl = "https://www.googletagmanager.com/gtag/js?id=" + window.ga4_stream;

    (function () {
        let ga4 = document.createElement('script');
        ga4.type = 'text/javascript';
        ga4.async = true;
        ga4.src = ga4ScriptUrl;
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ga4, s);
    })();

    // Configure GA4:
    window.dataLayer = window.dataLayer || [];

    function gtag() {
        dataLayer.push(arguments);
    }

    gtag('js', new Date());

    let ga4config = {};

    gtag('consent', 'default', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted'
    });

    if (typeof window.meerclick_no_ap !== 'undefined' && window.meerclick_no_ap === true) {
        ga4config.allow_google_signals = false;

        gtag('consent', 'update', {
            'ad_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
        });
    } else if (typeof window.meerclick_no_ap !== 'undefined' && window.meerclick_no_ap === false) {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted',
        });
    }

    gtag('config', window.ga4_stream, {'transport_type': 'beacon'});

    // Track all link clicks:
    document.addEventListener(`click`, e => {
        const origin = e.target.closest("a");

        if (origin !== null && typeof origin.href !== 'undefined') {
            // User clicked a link
            let href = origin.getAttribute("href") || origin.getAttribute("xlink:href");
            let linkDestination = parseUrl(href);

            // Should we track this link?
            // Check for outbound links (other domain) and meer-informatie links
            let shouldTrack = linkDestination.hostname !== location.hostname && "http" === linkDestination.protocol.slice(0, 4) || "/meer-informatie/" === linkDestination.pathname.slice(0, 17)

            // Also track link when conversion value is explicitly set:
            if (origin.getAttribute('data-mc-cval') !== null) {
                shouldTrack = true;
            }

            if (!shouldTrack) {
                return;
            }

            // Get all relevant information:
            let conversionValue = 1.00;
            let productType = null;
            let productID = null;
            let storkCampaign = null;
            let storkLink = null;

            // Get product ID and market from API link:
            let match = href.match(/api-([a-zA-Z0-9-]+)\.(?:whitelabeled|vergelijkgroep|keuze).*click\/[a-zA-Z0-9-]+\/([0-9]+)\//);

            if (match !== null) {
                productType = match[1];
                productID = match[2];
            }

            // Get stork information:
            let storkMatch = href.match(/meer-informatie\/([a-z0-9-]+)\/([a-z0-9-]+)/i);

            if (storkMatch !== null) {
                storkCampaign = storkMatch[1];
                storkLink = storkMatch[2];
            }

            if (origin.getAttribute('data-mc-pt') !== null) {
                productType = origin.getAttribute('data-mc-pt');
            }
            if (origin.getAttribute('data-mc-pid') !== null) {
                productID = origin.getAttribute('data-mc-pid');
            }

            // Get conversion value:
            if (origin.getAttribute('data-mc-cval') !== null) {
                conversionValue = origin.getAttribute('data-mc-cval');
            }

            // Log to GA4:
            gtag('event', 'mc_click', {
                currency: 'EUR',
                value: conversionValue,
                cval: conversionValue,
                product_type: productType,
                product_id: productID,
                stork_campaign: storkCampaign,
                stork_link: storkLink
            });
        }
    });
}

clientId = '';

function init_amplitude() {
    if (typeof amplitude === 'undefined') {
        (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
        ;r.type="text/javascript"
        ;r.integrity="sha384-d/yhnowERvm+7eCU79T/bYjOiMmq4F11ElWYLmt0ktvYEVgqLDazh4+gW9CKMpYW"
        ;r.crossOrigin="anonymous";r.async=true
        ;r.src="https://cdn.amplitude.com/libs/amplitude-5.2.2-min.gz.js"
        ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
            console.log("[Amplitude] Error: could not load SDK")}}
        ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
        ;function s(e,t){e.prototype[t]=function(){
            this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
            var o=function(){this._q=[];return this}
            ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
            ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
                ;return this}
            ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
            ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
            ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
            ;function v(e){function t(t){e[t]=function(){
                e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
                for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
                e=(!e||e.length===0?"$default_instance":e).toLowerCase()
                ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
            ;e.amplitude=n})(window,document);
    }

    amplitude.getInstance().init("1aeebe1796a391e2adb568ccc08136cd", window.global_session, false, amplitude_start);
//    amplitude.getInstance().setTransport('beacon');
}

function amplitude_start() {
    // First check last track link:

    if (localStorageTest()) {
        var trackLastClickData = localStorage.getItem('mc_tracklastclick');

        if (typeof trackLastClickData !== "undefined") {
            try {
                var parsedTrackData = JSON.parse(trackLastClickData);
            } catch (e) {
                parsedTrackData = null;
            }
            if (parsedTrackData !== null) {
                amplitude.getInstance().logEvent('click', parsedTrackData);
            }

            localStorage.removeItem('mc_tracklastclick')
        }
    }

    amplitude.getInstance().logEvent('pageview',
        {
            'domain': window.location.hostname,
            'url': window.location.href,
            'm_view': window.global_tracking
        }
    );

    var identify = new amplitude.Identify();

    identify.setOnce('start_domain', window.location.hostname);
    identify.setOnce('start_url', window.location.href);
    identify.setOnce('start_referrer', document.referrer);
    identify.setOnce('channel', window.global_channel);

    identify.prepend('urls_visited', window.location.href);

    amplitude.getInstance().identify(identify);
}

j_meer_loadScript("https://ajax.googleapis.com/ajax/libs/jquery/3.1.1/jquery.min.js", function () {
    var $m = window.jQuery.noConflict(true);
    window.global_m = $m;

    $m( "body" ).on( "mousedown", "a" , function() {
        var href = $m(this).attr('href');

        // Don't process non-existing href's
        if (typeof href === typeof undefined || href === false) {
            return;
        }

        var isExternalClick = $m(this).attr('href').match(/.*ds1.nl.*/) ||
            $m(this).attr('href').match(/.*[A-Za-z]{2}[0-9]{2}.net.*/) ||
            $m(this).attr('href').match(/.*[A-Za-z]{3}[0-9]{1}.net.*/) ||
            $m(this).attr('href').match(/.*ad.zanox.*/) ||
            $m(this).attr('href').match(/.*clk.tradedoubler.*/) ||
            $m(this).attr('href').match(/.*awin1.com.*/) ||
            $m(this).attr('href').match(/.*.whitelabeled.nl.*/) ||
            $m(this).attr('href').match(/.*.vergelijkgroep.nl.*/) ||
            $m(this).attr('href').match(/.*meer-informatie.*/);

        var isInternalAnchor = $m(this).attr('href').match(/^#.*/) || $m(this).attr('href').match(/^javascript:.*/);

        var target = $m(this).attr('target');
        var rel = $m(this).attr('rel');
        var classes = $m(this).attr('class');
        var clicktext = $m(this).text();

        var stork_link = '';
        var stork_campaign = '';

        if ( $m(this).attr('href').match(/.*meer-informatie.*/) ) {
            var url_breakdown = href.replace(/\/$/, "").split("/");
            stork_link = url_breakdown.pop();
            stork_campaign = url_breakdown.pop();
        }

        let productType = '';
        let productID = '';

        let match = href.match(/api-([a-zA-Z0-9-]+)\.(?:whitelabeled|vergelijkgroep|keuze).*click\/[a-zA-Z0-9-]+\/([0-9]+)\//);

        if (match !== null) {
            productType = match[1];
            productID = match[2];
        }

        if (typeof $m(this).attr('data-mc-pt') !== 'undefined') {
            productType = $m(this).attr('data-mc-pt');
        }
        if (typeof $m(this).attr('data-mc-pt') !== 'undefined') {
            productID = $m(this).attr('data-mc-pid');
        }

        var identify = new amplitude.Identify();

        var mcTrackData = {
            'type': 'click',
            'label': clicktext,
            'href': href,
            'rel': rel,
            'stork_campaign': stork_campaign,
            'stork_link': stork_link,
            'product_type': productType,
            'product_id': productID,
            'conversion_value': $m(this).attr('data-mc-cval'),
            'el_class': classes,
            'el_id': $m(this).attr('id'),
        };

        var trackData = {
            'domain': window.location.hostname,
            'url': window.location.href,
            'url_path': window.location.pathname,
            'm_view': window.global_tracking,
            'href': href,
            'target': target,
            'rel': rel,
            'class': classes,
            'label': clicktext,
            'stork_link': stork_link,
            'stork_campaign': stork_campaign,
            'referrer': document.referrer,
        };

        if (isExternalClick || isInternalAnchor) {
            amplitude.getInstance().logEvent('click', trackData);
        } else {
            // Check LS:
            if (localStorageTest()) {
                // Only for internal links:
                localStorage.setItem('mc_tracklastclick', JSON.stringify(trackData));
            } else {
                // Lets pray...
                amplitude.getInstance().logEvent('click', trackData);
            }
        }

        if (isExternalClick) {
            identify.set('last_click_url', window.location.href);
            identify.set('last_click_url_path', window.location.pathname);
            identify.set('last_click_href', href);
            identify.set('last_click_label', clicktext);
            identify.set('last_click_stork_link', stork_link);
            identify.set('last_click_stork_campaign', stork_campaign);

            amplitude.identify(identify);
        }

        let url = baseUrl + 'v1/event';
        let query = '?view=' + encodeURIComponent(window.global_tracking);

        $m.ajax(url + query, {
            data: JSON.stringify(mcTrackData),
            contentType: 'application/json',
            type: 'POST'
        });
    });


    $m(document).ready(function () {

        /* Loading adwords */
        var utmz = readCookie('__utmz');
        var pageurl = window.location.href;
        var referrer = document.referrer;

        var t_channel = getParameterByName('channel');

        var channel;
        if (t_channel !== "") {
            channel = t_channel;
            createCookie('m_channel', channel);
        } else {
            channel = readCookie('m_channel');
        }


        var t_session = readCookie('m_session');
        var session;
        if (t_session) {
            session = t_session;
        }

        if (!channel) channel = '';
        if (!session) session = '';
        if (!utmz) {
            utmz = ''
        } else {
            utmz = JSON.stringify(utmz);
        }

        if (channel === '' || channel === 'referral') {
            if (document.referrer !== null && document.referrer !== '') {
                var r_host = parseUri(document.referrer).host;

                if (r_host !== null && r_host !== '') {
                    channel = 'r_' + r_host;
                }
            }
        }

        createCookie('m_channel', channel);

        window.global_session = session;
        window.global_channel = channel;

        if (typeof meerclick_iframe !== 'undefined') {
            scriptmode = 'iframe';
            calltoParent();
        } else {
            scriptmode = 'parent';
            let url = baseUrl + 'v1/view/' + meerclicksite;
            let query = '?channel=' + encodeURIComponent(channel) + '&url=' + encodeURIComponent(pageurl) + '&referrer=' + encodeURIComponent(referrer) + '&ga_cid=' + encodeURIComponent(clientId) + '&utmz=' + encodeURIComponent(utmz) + '&session=' + encodeURIComponent(session) + '&gclid=';
            $m.getJSON(url + query, function (data) {

                window.global_session = data.session;
                window.global_tracking = data.tracking;

                if (data.session) {
                    createCookie('m_session', data.session);
                }
                createCookie('m_view', data.tracking);

                processWL($m,data.tracking);
                processLinks($m, data.tracking);
                init_amplitude();

                trackingreturn = true;
            });
        }
    });
});

function localStorageTest() {
    var test = 'mc_ls_test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

function calltoParent() {
    parent.postMessage('start_comms', "*");
}

function childCallBack($m) {
    if (trackingreturn) {
        var message = {};
        message.tracking = window.global_tracking;
        $m('iframe').each(function (index, elem) {
            elem.contentWindow.postMessage(message, "*")
        });
    } else {
        setTimeout(function () {
            childCallBack($m);
        }, 50);
    }
}

window.addEventListener("message", function (message) {
    if ((scriptmode == 'iframe') && (typeof message.data.tracking !== 'undefined')) {
        processLinks(window.global_m, message.data.tracking)
    }
    if (message.data == 'start_comms') {
        childCallBack(window.global_m);
    }
});

window.global_WL_tries = 0;
function processWL($m, tracking) {
    let wi_initialized = false;

    if (typeof wle_app !== "undefined") {
        wi_initialized = true;
        window.wle_app.tracking = tracking;
    } else if (typeof wli_app !== "undefined") {
        wi_initialized = true;
        window.wli_app.tracking = tracking;
    } else if (typeof app !== "undefined" && typeof app.fetchData !== "undefined") {
        wi_initialized = true;
        window.wlm_tracking = tracking;
        setTimeout(app.fetchData(), 250);
    } else if (typeof wlz_tracking !== "undefined") {
        wi_initialized = true;
        window.wlz_tracking = tracking;
    }

    if (!wi_initialized) {
        window.global_WL_tries++;
        if (window.global_WL_tries < 20) {
            setTimeout(function() { processWL($m, tracking) }, 500);
        }
    }
}

function processLinks($m, tracking) {
    var timeout = 0;
    var timeout_interval = 10;

    timeout = 20;
    $m('a').filter(function () {
        if (typeof $m(this).attr('href') === 'undefined') {
            return false;
        } else {
            return $m(this).attr('href').match(/.*.whitelabeled.nl.*/);
        }
    }).each(function (index) {
        var thisobj = this;
        timeout += timeout_interval;
        setTimeout(function () {
            replaceHrefURL($m, thisobj, tracking);
        }, timeout);
    });
}

function replaceHrefURL($m, ahrefelem, mid) {
    var curhref = $m(ahrefelem).attr('href');

    if (curhref.indexOf(mid) >= 0) {
        return false;
        /* prevent double replacing; happens if there are more instances of an iframe on a page, triggering more messages */
    }

    var newurl;
    mid = encodeURIComponent(mid);
    newurl = buildUrl(curhref, 't', mid);

    $m(ahrefelem).attr('href', newurl);
}

var buildUrl = function replaceUrlParam(url, paramName, paramValue) {
    if (paramValue == null)
        paramValue = '';
    var pattern = new RegExp('\\b(' + paramName + '=).*?(&|$)');
    if (url.search(pattern) >= 0) {
        return url.replace(pattern, '$1' + paramValue + '$2');
    }
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue
}

/* Cookie functions */
function createCookie(name, value, days) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    } else {
        expires = "";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/; secure; SameSite=None";
}

function readCookie(name) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

/* Parameter function */
function getParameterByName(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
    return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}
